<template>
  <!-- Projects Table Column -->
  <a-card 
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">Archived Categories</h5>
        </a-col>
        <a-col
          :span="24"
          :md="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <a-button
            shape="round"
            @click="confirmselect"
            type="primary"
            style="margin-left: 10px"
            >Delete selected</a-button
          >
          <a-button
            shape="round"
            @click="handleSelect2"
            type="primary"
            style="margin-left: 10px"
            >Recover selected</a-button
          >
        </a-col>
      </a-row>
    </template>
    <a-checkbox-group style="display: inline" @change="Selected">
      <a-table :columns="columns" :data-source="data" :pagination="true" v-show="data.length">
        <template slot="selectorColumn" slot-scope="row">
          <a-checkbox :value="row"></a-checkbox>
        </template>
        <template slot="name" slot-scope="text">
          <a>{{ text }}</a>
        </template>

        <template slot="editBtn" slot-scope="row">
          <a-button shape="circle" @click="recover(row)" icon="rollback">
          </a-button>
        </template>

        <template slot="navBtn" slot-scope="row">
          <a-button shape="circle" @click="categorydetail(row)" icon="right">
          </a-button>
        </template>

        <template slot="delBtn" slot-scope="row">
          <a-button shape="circle" @click="confirmdel(row)" icon="delete">
          </a-button>
        </template>

        <template slot="countProduct" slot-scope="products">
          {{ products ? products.length : 0 }}
        </template>
      </a-table>
    </a-checkbox-group>
    
  </a-card>
  <!-- / Projects Table Column -->
</template>

<script>
import { deletemore, recovercategory } from "@/api/category";
import { Modal } from "ant-design-vue";
import { message } from 'ant-design-vue';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Active button for the "Projects" table's card header radio button group.
      projectHeaderBtns: "all",
      selected: [],
    };
  },
  methods: {
    // function: category-13
    recover(id) {
      recovercategory(id).then((data) => {
        for (var i = 0; i < this.data.length; i++)
          if (this.data[i].categoryid == id) this.data.splice(i, 1);
        message.success('This category has been recovered successfully!');
      });
    },
    // function: category-12
    categorydetail(id) {
      this.$router.push({ path: "/categorydetail", query: { categoryid: id } });
    },
    // function: category-11
    delcategory(id) {
      deletemore(id).then((data) => {
        for (var i = 0; i < this.data.length; i++)
          if (this.data[i].categoryid == id) this.data.splice(i, 1);
        message.success('This category has been deleted successfully!');
      });
    },
    // function: category-11
    confirmdel(key) {
      Modal.confirm({
        title: "Are you sure to delete this category?",
        content: "This operation cannot be recovered",
        okText: "delete",
        cancelText: "cancel",
        onOk: () => {
          this.delcategory(key);
        },
      });
    },

    Selected(values) {
      this.selected = values;
    },
    // function: category-10
    confirmselect() {
      Modal.confirm({
        title: "Are you sure to delete selected category?",
        content: "This operation cannot be recovered",
        okText: "delete",
        cancelText: "cancel",
        onOk: () => {
          this.handleSelect();
        },
      });
    },
    // function: category-10
    handleSelect() {
      this.selected.forEach((item) => this.delcategory(item));
    },
    // function: category-14
    handleSelect2() {
      this.selected.forEach((item) => this.recover(item));
    },
  },
  
};
</script>