<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{data.length}} Categories</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-button shape="round" type="primary" @click="addCategory" icon="plus">
						Add category
					</a-button>
				</a-col>
			</a-row>
		</template>
		 <a-checkbox-group style="display:inline" @change="Selected">
		<a-table :columns="columns" :data-source="data" :pagination="true" v-show="data.length">
			<template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
			<template slot="name" slot-scope="text">
				<a>{{ text }}</a>
			</template>			

			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" @click="editcategory(row)" icon="edit">
				</a-button>
			</template>

			<template slot="navBtn" slot-scope="row">
				<a-button shape="circle" @click="categorydetail(row)" icon="right">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delcategory(row)" icon="folder">
				</a-button>
			</template>

			<template slot="countProduct" slot-scope="products">
				{{(products?products.length:0)}}
			</template>
		</a-table>
		</a-checkbox-group>
		<a-row type="flex" align="middle">
			<a-col :span="24" :md="12">
			</a-col>
			<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				<a-button shape="round" @click="handleSelect" type="primary" style="margin-left:10px">Archive selected</a-button>
			</a-col>
		</a-row>
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>
	import {deletecategory} from '@/api/category';
	import { message } from 'ant-design-vue';

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
				selected:[]
			}
		},
		methods: {
			addCategory() {
				this.$router.push({ path: "/categoryedit", query: { categoryid: 0 } });
			},
			// function: category-03
			editcategory(id){
				this.$router.push({path:'/categoryedit',query:{categoryid:id}})
			},
			// function: category-05
			categorydetail(id){
				this.$router.push({path:'/categorydetail',query:{categoryid:id}})
			},
			// function: category-04
			delcategory(id){
				deletecategory(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].categoryid==id)
							this.data.splice(i,1);
					message.success('This category has been archived successfully!');
				})
			},
			Selected(values){
            this.selected = values;
            },
			// function: category-06
			handleSelect(){
			this.selected.forEach(item => this.delcategory(item))
			}
		}
	})

</script>