<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <!-- Products Table -->
    <a-row :gutter="24" type="flex">
      <!-- Products Table Column -->
      <a-col :span="24" class="mb-24">
        <!-- Products Table Card -->
        <CardCategoriesTable
          :data="table1Data"
          :columns="table1Columns"
        ></CardCategoriesTable>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="24" class="mb-24">
      </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button v-if="button1" @click="showarchived" icon="down" type="primary" shape="round"
            >Show archived categories</a-button
          >
        </a-col>
    </a-row>
    <a-row>
        <CardArchivedCategoryTable
          :data="table2Data"
          :columns="table2Columns"
          v-if="isShown"
        ></CardArchivedCategoryTable>
    </a-row>
    <a-row>
      <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">

        <a-button v-if="button2" @click="hidearchived" icon="up" type="primary" shape="round"
          >Hide archived categories</a-button
        >
      </a-col>
    </a-row>    
  </div>
</template>

<script>
// "Products" table component.
import CardCategoriesTable from "../components/Cards/CardCategoriesTable";
import CardArchivedCategoryTable from "../components/Cards/CardArchivedCategoryTable";

import request from "../utils/request";

import { list, listmore } from "@/api/category";
// "Products" table list of columns and their properties.
const table1Columns = [
  {
    title: "",
    dataIndex: "categoryid",
    scopedSlots: { customRender: "selectorColumn" },
    width: 50,
  },
  {
    title: "Category name",
    dataIndex: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Products",
    dataIndex: "products",
    scopedSlots: { customRender: "countProduct" },
  },
  {
    title: "Modified date",
    dataIndex: "lastupdatetime",
    sorter: (a, b) => new Date(a.lastupdatetime) - new Date(b.lastupdatetime),
  },
  {
    title: "Edit",
    dataIndex: "categoryid",
    key: "editid",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
  {
    title: "Archive",
    dataIndex: "categoryid",
    key: "m",
    scopedSlots: { customRender: "delBtn" },
    width: 50,
  },
  {
    title: "More details",
    dataIndex: "categoryid",
    key: "n",
    scopedSlots: { customRender: "navBtn" },
    width: 60,
  },
];
const table2Columns = [
  {
    title: "",
    dataIndex: "categoryid",
    key: "namekey",
    scopedSlots: { customRender: "selectorColumn" },
    width: 50,
  },
  {
    title: "Category name",
    dataIndex: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Products",
    dataIndex: "products",
    scopedSlots: { customRender: "countProduct" },
  },
  {
    title: "Archived date",
    dataIndex: "archive",
    sorter: (a, b) => new Date(a.archive) - new Date(b.archive),
  },
  {
    title: "Use again",
    dataIndex: "categoryid",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
  {
    title: "Delete",
    dataIndex: "categoryid",
    key: "m",
    scopedSlots: { customRender: "delBtn" },
    width: 50,
  },
  {
    title: "More details",
    dataIndex: "categoryid",
    key: "n",
    scopedSlots: { customRender: "navBtn" },
    width: 60,
  },
];

export default {
  components: {
    CardCategoriesTable,
    CardArchivedCategoryTable,
  },
  methods: {
    // function- category-02
    
    // function- category-07
    showarchived() {
      this.isShown = true;
      this.button1 = false;
      this.button2 = true;
    },
    // function- category-09
    hidearchived() {
      this.isShown = false;
      this.button1 = true;
      this.button2 = false;
    },
  },
  data() {
    return {
      // Associating "Products" table data with its corresponding property.
      table1Data: [],
      table2Data: [],

      // Associating "Products" table columns with its corresponding property.
      table1Columns: table1Columns,
      table2Columns: table2Columns,
      isShown: false,
      button1: true,
      button2: false,
    };
  },
  watch: {
    table2Data: function (oldValue, newValue) {
      var temp = [];
      list().then((data) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].categorytype == 4) {
            temp.push(data[i]);
          }
        }
        if (temp.length != this.table1Data.length) {
          this.table1Data = temp;
        }
      });
    },
    table1Data: function (oldValue, newValue) {
      var temp = [];
      listmore().then((data) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].categorytype == 4) {
            temp.push(data[i]);
          }
        }
        if (temp.length != this.table2Data.length) {
          this.table2Data = temp;
        }
      });
    },
  },
  mounted() {
    var t1 = [];
    var t2 = [];
    // function: category-01
    list().then((data) => {
      for (var i = 0; i < data.length; i++) {
        if (data[i].categorytype == 4) {
          t1.push(data[i]);
        }
      }
      this.table1Data = t1;
    });
    // function: category-08
    listmore().then((data) => {
      for (var i = 0; i < data.length; i++) {
        if (data[i].categorytype == 4) {
          t2.push(data[i]);
        }
      }
      this.table2Data = t2;
    });
  },
};
</script>

<style lang="scss">
</style>